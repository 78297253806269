/* Main Sidebar */
aside {
	width: 275px;
	min-width: 190px;
	height: 100%;
	box-shadow: rgba(90, 97, 105, 0.1) 0px 0.125rem 9.375rem,
		rgba(90, 97, 105, 0.12) 0px 0.25rem 0.5rem,
		rgba(90, 97, 105, 0.1) 0px 0.9375rem 1.375rem,
		rgba(165, 182, 201, 0.1) 0px 0.4375rem 2.1875rem;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	overflow-x: hidden;
	background-color: #fff;
}
aside::-webkit-scrollbar {
	display: none;
}

/* Head */
.aside-head {
	width: 100%;
	height: 60px;
	text-align: center;
	border-bottom: 1px solid #e1e5eb;
	position: relative;
}

.aside-head a {
	height: 100%;
	width: 100%;
	text-decoration: none;
	color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 25px;
}

.aside-head a:hover {
	color: rgba(0, 0, 0, 1);
}

.aside-head a img {
	height: 25px;
	width: 25px;
	object-fit: cover;
	margin-right: 5px;
}

.toggle-sidebar {
	display: none;
}

/* Aside Items */
.aside-item {
	width: 100%;
	text-align: left;
}

.aside-item a {
	color: inherit;
	text-decoration: none;
}

/* Button */
.aside-item button {
	border: none;
	outline: none;
	background-color: inherit;
	border-bottom: 1px solid #e1e5eb;
	cursor: pointer;
	height: 50px;
	width: 100%;
	padding-left: 25px;
	color: #3d5170;
	display: flex;
	align-items: center;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border-left: 4px solid transparent;
	transition: all 0.2s linear;
}

.aside-item i {
	color: #cacedb;
}

.aside-item span {
	margin-left: 9px;
}

/* Button Hover Effects */
.aside-item button:hover,
.aside-item:hover button i,
.aside-item:hover .dropdown-container a span,
.aside-item:hover .dropdown-container a i {
	color: var(--primary);
	transition: all 0.2s linear;
}

.aside-item:hover button {
	border-left: 4px solid var(--primary);
}

/* Dropdown */
.dropdown-container {
	display: flex;
	flex-direction: column;
	background-color: #f5f6f8;
	padding-left: 23px;
	display: none;
}

.dropdown-container a {
	text-decoration: none;
	height: 100%;
	width: 100%;
	padding: 13px 0 13px 25px;
	border-bottom: 1px solid #e1e5eb;
	color: #3d5170;
	display: flex;
	align-items: center;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border-left: 3px solid transparent;
	transition: all 0.2s linear;
}

/* Dropdown items hover effects */
.dropdown-container a:hover {
	background-color: white;
}

.dropdown-container a:hover {
	border-left: 3px solid var(--primary);
}

/* Active link styles */

a.nav-active button {
	border-left: 4px solid var(--primary);
}

a.nav-active button i {
	color: var(--primary);
	transition: all 0.2s linear;
}

a.dropdown-active {
	border-left: 3px solid var(--primary);
}

a.dropdown-active i,
a.dropdown-active span {
	color: var(--primary);
	transition: all 0.2s linear;
}

/* Media Query */
@media only screen and (min-width: 775px) and (max-width: 1300px) {
	aside {
		width: 200px;
	}
	.dropdown-container {
		padding-left: 10px;
	}
}

@media only screen and (max-width: 775px) {
	/* Mian Sidebar */
	aside {
		width: 100%;
		transform: translateX(-100%);
		transition: all 0.2s ease-in-out;
	}

	#sidebar {
		transform: translateX(0);
	}

	/* Toggle Button */
	.toggle-sidebar {
		display: inline;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		border-left: 1px solid #e1e5eb;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.1rem;
		width: 60px;
		color: #5a6169;
		cursor: pointer;
	}
}
